import {createStore, Store, useStore as baseUseStore} from 'vuex'
import {InjectionKey} from "vue";
import {
    CustomerFinanceState,
    CustomerLimits,
    CustomerPurchaseAnalytic,
    CustomerReference,
    Department
} from "@/app/customer/Customers";
import {Cart} from "@/app/cart/Cart";
import {loadCart} from "@/app/cart/CartRepository";
import {updateLastSelectedDepartment} from "@/app/customer/CustomerRepository";
import {AppStateUser} from "@/common/interfaces";
import {PERMISSION_FINANCE_ACCESS, PERMISSION_ORDER_ACCESS} from "@/app/Security";
import {CustomerSegmentItem} from "@/admin/customers/AdminCustomers";

export interface Init {
    customer?: AppStateCustomer | null;
    defaultOrderMinimumWeight: number;
    user?: AppStateUser | null;
}

export interface AppState {
    customer?: AppStateCustomer | null;
    orderMinimumWeight: number;
    user?: AppStateUser | null;
    currentDepartment?: Department | null;
    cart?: Cart | null;
    showMainMenu: boolean;
    likeAsCustomer: boolean,
    showCustomerSelector: boolean;
    showDepartmentsSelector: boolean;
}

export interface AppStateCustomer {
    id: string;
    name: string;
    palletMode: boolean;
    forExport: boolean;
    currency: string;
    complaintsAccess: boolean;
    departments: Department[];
    creditLimit: CustomerLimits;
    segment: CustomerSegmentItem | null;
    analytic: CustomerPurchaseAnalytic;
    financeState: CustomerFinanceState;
    lastSelectedDepartment?: string | null;
    externalCode: string;
}

export const key: InjectionKey<Store<AppState>> = Symbol()

export const store = createStore<AppState>({
    state: {
        customer: null,
        orderMinimumWeight: 0,
        user: null,
        currentDepartment: null,
        showMainMenu: true,
        likeAsCustomer: false,
        showCustomerSelector: false,
        showDepartmentsSelector: false,
    },
    getters: {
        isInitialized(state) {
            return state.user !== null;
        },
        user(state) {
            return state.user
        },
        customer(state): AppStateCustomer | null | undefined {
            return state.customer
        },
        customerReference(state): CustomerReference | null {
            return state.customer ? {
                id: state.customer.id,
                name: state.customer.name,
                externalCode: state.customer.externalCode,
            } : null
        },
        departments(state) {
            if (!state.customer) {
                return [];
            }
            return state.customer.departments.filter((department: Department) => {
                return !department.isMain;
            })
        },
        currentDepartment(state) {
            return state.currentDepartment;
        },
        likeAsCustomer(state) {
            return state.likeAsCustomer;
        },
        isDepartmentSelected(state) {
            return state.currentDepartment !== null;
        },
        isNotAvailableForUser(state) {
            return state.currentDepartment === null;
        },
        orderMinimumWeight(state) {
            return state.orderMinimumWeight
        },
        cart(state) {
            return state.cart
        },
        showDepartmentsSelector(state) {
            return state.showDepartmentsSelector;
        },
        showCustomerSelector(state) {
            return state.showCustomerSelector;
        },
        customerUsesPalletMode(state) {
            return state.customer?.palletMode;
        },
        isManagementRole(state) {
            return state.user ? (state.user.roles.indexOf('ROLE_ADMIN') !== -1 || state.user.roles.indexOf('ROLE_MANAGER') !== -1) : false;
        },
        isAdminRole(state) {
            return state.user ? (state.user.roles.indexOf('ROLE_ADMIN') !== -1) : false;
        },
        roles(state) {
            return state.user ? state.user.roles : [];
        },
        hasOrderAccess(state) {
            return state.user ? (state.user.roles.indexOf(PERMISSION_ORDER_ACCESS) !== -1) : false;
        },
        hasFinanceStateAccess(state) {
            return state.user ? (state.user.roles.indexOf(PERMISSION_FINANCE_ACCESS) !== -1) : false;
        },
    },
    mutations: {
        init(state, payload: Init) {
            state.customer = payload.customer;

            if(payload.customer && payload.customer.segment){
                state.orderMinimumWeight = payload.customer.segment.orderWeightLimit;
            }else{
                state.orderMinimumWeight = payload.defaultOrderMinimumWeight;
            }
            state.user = payload.user;

            if (!payload.customer) {
                return;
            }

            if (payload.customer.lastSelectedDepartment && payload.customer.departments.length > 0) {
                const department = payload.customer.departments.find((department: Department) => department.id === state.customer?.lastSelectedDepartment);
                if (department) {
                    state.currentDepartment = department;
                    return;
                }

                state.currentDepartment = payload.customer.departments[0];
            } else {
                if (payload.customer.departments.length === 1) {
                    state.currentDepartment = payload.customer.departments[0];
                } else {
                    state.currentDepartment = payload.customer.departments.filter((department: Department) => !department.isMain)[0] ?? null;
                }
            }

        },
        initCart(state, cart: Cart) {
            state.cart = cart;
        },
        setShowMainMenu(state, value: boolean) {
            state.showMainMenu = value;
        },
        setShowCustomerSelector(state, value: boolean) {
            if (value) {
                state.customer = null;
            }
            state.showCustomerSelector = value;
        },
        setShowDepartmentsSelector(state, value: boolean) {
            if (value && state.customer && state.customer.departments.length <= 1) {
                return;
            }
            state.showDepartmentsSelector = value;
        },
        changeDepartment(state, department: Department) {
            state.currentDepartment = department;
            state.showDepartmentsSelector = false;
            updateLastSelectedDepartment(department.id);
        },
        changeCustomer(state, customer: AppStateCustomer) {
            state.customer = customer;
            if(customer.segment){
                state.orderMinimumWeight = customer.segment.orderWeightLimit;
            }
        },
        changeDepartmentForCustomer(state, department: Department) {
            state.showCustomerSelector = false;
            state.currentDepartment = department;
            state.likeAsCustomer = true;
        },
        clearLikeAsCustomer(state) {
            state.customer = null;
            state.currentDepartment = null;
            state.likeAsCustomer = false;
        }
    },
    actions: {
        reloadCart({state}) {
            if (state.customer && state.currentDepartment) {
                loadCart({
                    customer: {
                        id: state.customer.id,
                        name: state.customer.name,
                        externalCode: state.customer.externalCode,
                    },
                    department: {
                        id: state.currentDepartment.id,
                        name: state.currentDepartment.name,
                        externalCode: state.currentDepartment.externalCode
                    },
                })
                    .then((cart: Cart) => state.cart = cart)
            }
        },
    },
    modules: {}
})

export function useStore() {
    return baseUseStore(key)
}
